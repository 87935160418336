import React, { Component, Fragment } from 'react';
import { Button, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  select: {
    width: '90%'
  },
  numericField: {
    border: '1px',
    width: '90%',
    float: 'right',
    textAlign: 'right'
  },
  button: {
    margin: theme.spacing.unit
  },
  error: {
    color: 'red',
    textAlign: 'right',
    fontSize: '70%'
  },
  segmentNumber: {
    textAlign: 'center'
  }
});


class HighHeatSegmentForm extends Component {
  render() {
    const { classes, segment, updateSegmentField } = this.props;
    const index = segment.index || 0;

    const errors = this.props.validateSegment(segment);
    const startIndex = 100 + (segment.index * 10);
    return <Fragment>
      <Grid key={`key-${startIndex}`}  item xs={1} className={classes.segmentNumber}>
        {segment.index}
      </Grid>
      <Grid key={`key-${startIndex + 1}`}  item xs={3}>
        <Select
          header='&nbsp;'
          className={classes.select}
          value={segment.segment_type}
          inputProps={{
            name: 'name',
            id: 'id',
          }}
          disabled={true}
          onChange={ (ev) => { updateSegmentField(index, 'segment_type', ev); } } >
          <MenuItem key='ramp_rate' id='ramp_Rate' value='ramp_rate'>Ramp Rate</MenuItem>
          <MenuItem key='dwell' id='dwell' value='dwell'>Dwell</MenuItem>
        </Select>
      </Grid>
      <Grid key={`key-${startIndex + 2}`}  item xs={2}>

        <TextField className={classes.numericField}
          id={`target_setpoint-${segment.index}`}
          type='number'
          disabled={segment.segment_type==='dwell'}
          value={segment.target_setpoint}
          helperText={''}
          onChange={ (ev) => { updateSegmentField(index, 'target_setpoint', ev); } }
          InputProps={{ endAdornment:
                        <InputAdornment position="end">C</InputAdornment> ,
          classes: { input: classes.numericField },
          inputProps: { min: 0 }
          }}
          error={ (errors.target_setpoint) ? true : false}
          margin="normal"
        />
        <br/>
        {errors.target_setpoint ? <Typography classes={{ root: classes.error }} >{errors.target_setpoint}</Typography> : <Fragment/>}
      </Grid>
      <Grid key={`key-${startIndex + 3}`}  item xs={2}>
        <TextField className={classes.numericField}
          id="ramp_rate"
          type='number'
          disabled={segment.segment_type==='dwell'}
          value={segment.ramp_rate}
          helperText={segment.description}
          onChange={ (ev) => { updateSegmentField(index, 'ramp_rate', ev); } }
          InputProps={{ endAdornment:
                    <InputAdornment position="end">C/min</InputAdornment> ,
          classes: { input: classes.numericField },
          inputProps: { min: 0 }
          }}
          error={ (errors.ramp_rate) ? true : false}
          margin="normal"
        />
        <br/>
        {errors.ramp_rate ? <Typography classes={{ root: classes.error }} >{errors.ramp_rate}</Typography> : <Fragment/>}
      </Grid>
      <Grid key={`key-${startIndex + 4}`}  item xs={1}>
        <TextField className={classes.numericField}
          id="duration_hours"
          type='number'
          value={parseFloat(segment.duration_hours)}
          helperText={segment.description}
          disabled={!(segment.segment_type==='dwell')}
          onChange={ (ev) => { updateSegmentField(index, 'duration_hours', ev); } }
          InputProps={{ endAdornment:
                        <InputAdornment position="end">hours</InputAdornment> ,
          classes: { input: classes.numericField },
          inputProps: { min: 0 }
          }}
          error={ (errors.duration_hours) ? true : false}
          margin="normal"
        />
        <br/>
        {errors.duration_hours ? <Typography classes={{ root: classes.error }} >{errors.duration_hours}</Typography> : <Fragment/>}
      </Grid>
      <Grid key={`key-${startIndex + 5}`}  item xs={1}>
        <TextField className={classes.numericField}
          id="duration_minutes"
          type='number'
          value={parseInt(segment.duration_minutes)}
          helperText={segment.description}
          disabled={!(segment.segment_type==='dwell')}
          onChange={ (ev) => { updateSegmentField(index, 'duration_minutes', ev); } }
          InputProps={{ endAdornment:
                        <InputAdornment position="end"> minutes</InputAdornment> ,
          classes: { input: classes.numericField },
          inputProps: { min: 0, max: 59 }
          }}
          error={ (errors.duration_minutes) ? true : false}
          margin="normal"
        />
        <br/>
        {errors.duration_minutes ? <Typography classes={{ root: classes.error }} >{errors.duration_minutes}</Typography> : <Fragment/>}
      </Grid>

      <Grid key={`key-${startIndex + 6}`}  item xs={2}>
        <Button variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => { this.props.deleteSegmentRow(segment.index); }}>- </Button>
      </Grid>
    </Fragment>;
  }
}

HighHeatSegmentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteSegmentRow: PropTypes.func,
  validateSegment: PropTypes.func,
  updateSegmentField: PropTypes.func,
  segment: PropTypes.object,
};

export default withStyles(styles)(HighHeatSegmentForm);
