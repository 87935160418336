import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

class DeleteConfirmationDialog extends Component {

  render() {
    return (
      <Dialog
        open={this.props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.text ? this.props.text : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.props.deletePending ?
            <div style={{ margin: 10 }}>
              <CircularProgress />
            </div>
            :
            <React.Fragment>
              <Button onClick={this.props.handleClose} style={{ marginRight: 10 }} variant="contained" color="primary">
                Cancel
              </Button>
              <Button onClick={this.props.handleDeleteConfirmed} style={{ margin: 10 }} variant="contained" color="secondary" autoFocus>
                Delete
              </Button>
            </React.Fragment>}
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeleteConfirmed: PropTypes.func.isRequired,
  deletePending: PropTypes.bool.isRequired,
};

export default DeleteConfirmationDialog;