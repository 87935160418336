import * as Msal from 'msal';
/* Extract MSLA token and make sure it's valid.
MSAL currently has a bug in its "getTokenSilent" functionality */

class AuthService {
  constructor() {
    let redirectUri = window.location.origin;

    this.applicationConfig = {
      auth: {
        clientId: '15e778a7-01d4-4262-b78a-eecde36c9020',
        authority: 'https://login.microsoftonline.com/facac3c4-e2a5-4257-af76-205c8a821ddb',
        redirectUri: redirectUri
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    };

    this.loginRequest = {
      scopes: ['user.read', 'openid'],
      prompt: 'select_account'
    };

    this.silentLoginRequest = {
      scopes: ['user.read', 'openid']
    };

    this.app = new Msal.UserAgentApplication(this.applicationConfig);
    this.app.handleRedirectCallback(() => {});
  }

  login = () => {
    return this.app.loginRedirect(this.loginRequest);
    // let self = this;
    // return this.app.loginPopup(this.loginRequest).then(
    //   idToken => {
    //     console.log(idToken);
    //     const user = self.app.getAccount();
    //     if (user) {
    //       return user;
    //     } else {
    //       return null;
    //     }
    //   },
    //   () => {
    //     return null;
    //   }
    // );
  }

  logout = () => {
    this.app.logout();
  }

  getToken = () => {
    return this.app.acquireTokenSilent(this.silentLoginRequest).then(
      accessToken => {
        console.log(accessToken);
        return accessToken;
      },
      error => {
        console.debug(error);
        return this.app
          .acquireTokenPopup(this.loginRequest)
          .then(
            accessToken => {
              return accessToken;
            },
            err => {
              console.error(err);
            }
          );
      }
    );
  }
  async extractMSALToken() {
    const timestamp = Math.floor((new Date()).getTime() / 1000);
    let token = null;

    for (const key of Object.keys(localStorage)) {
      if (key.includes('"authority":')) {
        const val = JSON.parse(localStorage.getItem(key));
        if (val.expiresIn) {
          // We have a (possibly expired) token

          if (val.expiresIn > timestamp && val.idToken === val.accessToken) {
            // Found the correct token
            token = val.idToken;
          }
          else {
            // Clear old data
            localStorage.removeItem(key);
          }
        }
      }
    }
    if (token) {
      return token;
    } else {
      // No valid token found
      return null;
    }
  }

  isAuthenticated = () => {
    const timestamp = Math.floor((new Date()).getTime() / 1000);
    let token = null;

    for (const key of Object.keys(localStorage)) {
      if (key.includes('"authority":')) {
        const val = JSON.parse(localStorage.getItem(key));

        if (val.expiresIn) {
          // We have a (possibly expired) token

          if (val.expiresIn > timestamp && val.idToken === val.accessToken) {
            // Found the correct token
            token = val.idToken;
          }
          else {
            // Clear old data
            localStorage.removeItem(key);
          }
        }
      }
    }
    return token !== null;
  }
}

export let authService = new AuthService();