import { makeRequest, toQueryString } from './BaseRequest';

const ENDPOINT = '/parts';

/**
 *
 * @param {string} processOrderId   Process order id.
 * @param {string} partNumber       Part number associated with the process order.
 * @returns {Promise<Response>}     Fetch call for retrieving a part from a process order.
 */
export async function getParts(processOrderId, flatten = false, partNumber = '') {
  let queryParameters = {
    'process_order_id': processOrderId,
  };

  if (partNumber !== '')
    queryParameters['part_number'] = partNumber;
  if (flatten !== false)
    queryParameters['flatten'] = true;

  let getPartEndpoint = ENDPOINT + '?' + toQueryString(queryParameters);
  return makeRequest(getPartEndpoint, 'GET');
}


export async function putRoughness(processOrderId, partNumber, roughness) {
  let body = {
    processorder: {id: processOrderId},
    part: {number: partNumber},
    roughness: roughness
  };
  let putRoughnessEndpoint = `${ENDPOINT}/roughness`;
  return makeRequest(putRoughnessEndpoint, 'PUT', body);
}

export async function putRoughnesses(roughnesses) {
  let putRoughnessEndpoint = `${ENDPOINT}/roughnesses`;
  return makeRequest(putRoughnessEndpoint, 'PUT', roughnesses);
}