import {
  makeRequest,
  toQueryString
} from './BaseRequest';

const ENDPOINT = '/process_step';

export function getTemplateProcessSteps(processStepType) {
  return makeRequest(`${ENDPOINT}?template_type=${processStepType}`, 'GET');
}

export function getTemplateByID(templateID) {
  return makeRequest(`${ENDPOINT}?template_step_id=${templateID}`, 'GET');
}

export function deleteTemplateProcessStep(templateID) {
  return makeRequest(ENDPOINT, 'DELETE', {
    template_step_id: templateID
  });
}

export function getProcessStep(processOrderId, type) {
  let queryParamters = {
    process_order_id: processOrderId,
    type: type
  };
  let processStepEndpoint = ENDPOINT + '?' + toQueryString(queryParamters);
  return makeRequest(processStepEndpoint, 'GET');
}

export function getProcessSteps() {
  return makeRequest(ENDPOINT, 'GET');
}

export function postProcessStep(processStep) {
  return makeRequest(ENDPOINT, 'POST', processStep);
}