import { authService } from '../../service/auth.service';

export async function makeRequest(path, method, body) {
  let token = await authService.extractMSALToken();
  let request = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Host': process.env.REACT_APP_TAMINO_API_HOST_HEADER,
      'Authorization': 'Bearer ' + token
    },
    ...(body && {body: JSON.stringify(body)})
  };

  return fetch(process.env.REACT_APP_TAMINO_API_ENDPOINT + path, request);
}

/*
    data: Array of form elements or set of key/values to pass as query parameters.
    return: Query string.
 */
export function toQueryString(data) {
  return Object.keys(data).map(function(k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
  }).join('&');
}
