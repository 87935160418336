import React, { Component, Fragment } from 'react';
import { Button, Fab, Grid, InputAdornment, MenuItem, Paper, TextField, Tooltip, Typography } from '@material-ui/core/';
import ContainerDimensions from 'react-container-dimensions';
import { withStyles } from '@material-ui/core/styles';
import copy from 'fast-copy';
import {
  HIGH_HEAT_RING_TYPES
} from './data/process-steps';
import SaveIcon from '@material-ui/icons/Save';
import HighHeatSegmentForm from './high-heat-segment-form';
import * as processStepRequest from '../rest/ProcessStepRequests';
import PropTypes from 'prop-types';
import {
  Chart, Dots, Labels, Lines, Ticks, Title, Animate
} from 'rumble-charts';

const ringTypeSuggestions = HIGH_HEAT_RING_TYPES.sort().map((t) => { return { label: t, id: t }; });
const ROOM_TEMP = 22;
const DEGREES_CELSIUS_SYMBOL = '\xB0C';

function getDuration(current_temp, target_temp, ramp_rate) {
  if(ramp_rate !== 0){
    return Math.abs(Math.floor((target_temp - current_temp) / ramp_rate));
  } else {
    return 0;
  }
}

export function extractDataPoints (segments){
  let total_duration = 0;
  let data = [];
  let ticks = [];

  data.push({ x: 0, y: ROOM_TEMP, color: 'red', label: '0m' });

  let names = ['Room Temp'];

  segments.forEach((segment) => {
    const duration_hours = isNaN(segment.duration_hours) ? 0 : segment.duration_hours;
    const duration_minutes = isNaN(segment.duration_minutes) ? 0 : segment.duration_minutes;
    total_duration += (duration_hours * 60 + duration_minutes);
    data.push({
      x: total_duration,
      y: isNaN(segment.target_setpoint) ? 0 : segment.target_setpoint,
      label: `${total_duration}m`
    });
    ticks.push({
      x: total_duration,
      y: isNaN(segment.target_setpoint) ? 0 : segment.target_setpoint,
      label: `${total_duration}m`,
      color: 'red'
    });
    names.push(isNaN(segment.target_setpoint) ? 0 : segment.target_setpoint);
  });

  let duration = getDuration(data[data.length-1].y, ROOM_TEMP, segment_fields.ramp_rate.max);
  total_duration += duration;
  data.push({ x: total_duration, y: ROOM_TEMP, color: 'red', label: `${total_duration}C` });
  ticks.push({
    x: total_duration,
    y: ROOM_TEMP,
    label: `${total_duration}m`,
    color: 'red'
  });
  names.push(ROOM_TEMP);

  const series = [{
    name: 'Temperature',
    data: data.length === 2 ? [] : data,
    ticks: ticks.length === 2 ? [] : ticks,
  }];
  return {
    series: series,
    ticks: ticks,
    total_duration: total_duration,
  };
}


const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  ringTypeLabel: {
    marginLeft: 36,
    fontSize: '80%'
  },
  segmentHeadersLeft: {
    backgroundColor: 'yellow',
    minWidth: 140,
    maxWidth: 140
  },
  item: {
    marginLeft: 48,
    paddingLeft: 40
  },
  button: {
    margin: theme.spacing.unit
  },
  GridArea: {
    backgroundColor: '#f8f8f8',
    border: '1px solid black',
    marginRight: 50,
    borderRadius: 12
  },
  DeleteFab: {
    right: 18,
    bottom: 120,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },

  DeleteIconLink: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '150%'
  },
  SaveFab: {
    right: 18,
    bottom: 40,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  CompareFab: {
    right: 18,
    bottom: 280,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  ArrowBackIcon: {

  },
  AddIconLink: {
    textDecoration: 'none',
    backgroundColor: 'yellow',
    color: 'white',
    fontSize: '150%'
  },
  CloneFab: {
    right: 18,
    bottom: 200,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  InputLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '80%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  leftItem: {
    textAlign: 'left',
    marginLeft: 40,
    paddingLeft: 40,
    minWidth: 240
  },
  error: {
    color: 'red',
    fontSize: '85%'
  },
  rightItem: {
    textAlign: 'left',
    marginRight: 40,
    minWidth: 240
  },
  numericField: {
    border: '1px',
    width: '90%',
    float: 'right',
    textAlign: 'right'
  },
  Fab: {
    backgroundColor: theme.palette.text.secondary
  },
  inputRight: {
    textAlign: 'right'
  },
  inputCenter: {
    textAlign: 'center'
  },
  textField: {
    width: '90%'
  },
  rightSelect: {
    textAlign: 'right',
    marginLeft: 36,
    width: '90%'
  },
  select: {
    width: '90%'
  },
  deleted: {
    textAlign: 'center',
    fontFamily: 'Tahoma',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#CC0000'
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  }
});

const segment_fields = {
  target_setpoint: {
    name: 'Target Setpoint',
    min: 0,
    typical: 580,
    max: 1200
  },
  ramp_rate: {
    name: 'Ramp Rate',
    min: 0.01,
    typical: 3,
    max: 7
  },
  duration_minutes: {
    name: 'Duration Minutes',
    min: 0,
    typical: 0,
    max: 59
  },
  duration_hours: {
    name: 'Duration Hours',
    min: 0,
    typical: 1,
    max: 100,
  }
};
const step_fields = {
  mass_limit: {
    name: 'Mass Limit',
    min: 0,
    typical: 10,
    max: 1000000000
  },
  number_of_rings: {
    name: 'Number of Rings',
    min: 0,
    typical: 1,
    max: 1000000000
  },
  tolerance: {
    name: 'Tolerance',
    min: 0,
    typical: 3,
    max: 10000
  }
};

const saveMessage = 'Save Process Step';

class HighHeatStepForm extends Component {

  constructor(props) {
    super(props);
    window.highHeatStepFormTest = this;
    window.highHeatStepFormTestResults = {};
    this.state = {
      step: {
        segments: []
      }
    };

    this.addEmptySegment = this.addEmptySegment.bind(this);
    this.deleteSegmentRow = this.deleteSegmentRow.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.updateSegmentField = this.updateSegmentField.bind(this);
    this.updateStepField = this.updateStepField.bind(this);
    this.updateAllSegments = this.updateAllSegments.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.validate = this.validate.bind(this);
    this.validateSegment = this.validateSegment.bind(this);
  }

  addEmptySegment() {

    const state = copy(this.state);
    let last_segment = {};
    let needs_update = false;
    if(state.step.segments.length !== 0) {
      last_segment = state.step.segments[state.step.segments.length - 1];
    } else {
      last_segment.segment_type = 'dwell';
      last_segment.target_setpoint = segment_fields.target_setpoint.typical;
      needs_update = true;
    }
    const next_segment_type = last_segment.segment_type === 'ramp_rate' ? 'dwell' : 'ramp_rate';
    const next_target_setpoint = last_segment.target_setpoint + 100 < segment_fields.target_setpoint.max ? last_segment.target_setpoint + 100 : last_segment.target_setpoint - 100;
    let duration = getDuration(last_segment.target_setpoint, next_target_setpoint, segment_fields.ramp_rate.typical);


    state.step.segments.push({
      index: state.step.segments.length,
      segment_type: next_segment_type,
      target_setpoint: next_segment_type === 'dwell' ? last_segment.target_setpoint : next_target_setpoint,
      ramp_rate: (next_segment_type === 'ramp_rate') ? segment_fields.ramp_rate.typical: 0,
      duration_hours: next_segment_type === 'dwell' ? segment_fields.duration_hours.typical: Math.floor(duration / 60),
      duration_minutes: next_segment_type === 'dwell' ? segment_fields.duration_minutes.typical: duration % 60,
    });
    if(needs_update){
      this.updateAllSegments(state);
    }
    this.setState(state);
  }

  deleteSegmentRow(index) {
    const state = copy(this.state);
    state.step.segments.splice(index, 1);

    // state.step.segments = state.step.segments.filter((s) => {
    //   return s.index !== index;
    // });

    // TODO calls updateAllSegments
    this.updateAllSegments(state);
    this.setState(state);
  }

  updateAllSegments(state) {
    const segments = state.step.segments;
    if(segments.length > 1) {
      let last_segment = segments[0];
      last_segment.index = 0;
      if (last_segment.segment_type === 'dwell') {
        last_segment.ramp_rate = segment_fields.ramp_rate.typical;
      }
      last_segment.segment_type = 'ramp_rate';
      let duration = getDuration(ROOM_TEMP, last_segment.target_setpoint, last_segment.ramp_rate);
      last_segment.duration_minutes = duration % 60;
      last_segment.duration_hours = Math.floor(duration / 60);

      for(let i = 1; i < segments.length; i++) {
        let current_segment = segments[i];
        // ...
        if(last_segment.segment_type === 'dwell') {
          let current_segment_temp = isNaN(current_segment.target_setpoint) ? 0 : current_segment.target_setpoint;
          let last_segment_temp = isNaN(last_segment.target_setpoint) ? 0 : last_segment.target_setpoint;
          current_segment.segment_type = 'ramp_rate';
          let duration = getDuration(last_segment_temp, current_segment_temp, current_segment.ramp_rate);
          current_segment.duration_minutes = duration % 60;
          current_segment.duration_hours = Math.floor(duration / 60);
        } else {
          if(current_segment.segment_type === 'ramp_rate') {
            current_segment.duration_minutes = segment_fields.duration_minutes.typical;
            current_segment.duration_hours = segment_fields.duration_hours.typical;
          }
          current_segment.segment_type = 'dwell';
          current_segment.target_setpoint = last_segment.target_setpoint;
        }
        current_segment.index = i;
        // ...
        last_segment = current_segment;
      }
    } else if (segments.length === 1) {
      let current_segment = segments[0];
      let duration = getDuration(ROOM_TEMP, current_segment.target_setpoint, current_segment.ramp_rate);
      current_segment.segment_type = 'ramp_rate';
      current_segment.duration_minutes = duration % 60;
      current_segment.duration_hours = Math.floor(duration / 60);
      current_segment.index = 0;
    }
    return state;
  }

  validate() {
    const errors = {};

    if (!this.state.step.step_name) {
      errors.step_name = 'Name is required';
    }
    else if (this.state.step.step_name.length < 5) {
      errors.step_name = 'Name should be at least 5 characters';
    }
    if (!this.state.step.ring_type) {
      errors.ring_type = 'Please select ring type';
    }

    if(this.state.step.segments.length === 0) {
      errors.number_of_segments = 'Furnace profile requires at least 1 segment.';
    }

    Object.keys(step_fields).filter((field) => {
      if (step_fields[field].min && this.state.step[field] < step_fields[field].min) {
        errors[field] = `${step_fields[field].name} must be ${step_fields[field].min} or higher`;
      }
      else if (step_fields[field].max && this.state.step[field] > step_fields[field].max) {
        errors[field] = `${step_fields[field].name} must be ${step_fields[field].max} or lower`;
      }
      else if (isNaN(this.state.step[field])) {
        errors[field] = `${step_fields[field].name} must have a numeric value.`;
      }

      return false;
    });

    return errors;
  }

  validateSegment(segment) {
    const errors = {};

    Object.keys(segment_fields).filter((field) => {
      if (segment_fields[field].min && segment[field] < segment_fields[field].min) {
        if (!(segment.segment_type === 'dwell' && segment.ramp_rate === 0)) {
          errors[field] = `${segment_fields[field].name} must be ${segment_fields[field].min} or higher`;
        }
      }

      if (segment_fields[field].max && segment[field] > segment_fields[field].max) {
        errors[field] = `${segment_fields[field].name} must be ${segment_fields[field].max} or lower`;
      }

      if (isNaN(segment[field])) {
        errors[field] = `${segment_fields[field].name} must have a numeric value.`;
      }

      return false;
    });

    return errors;
  }

  toggleOpen() {
    const state = copy(this.state);
    window.process_step_history_open = state.open = !window.process_step_history_open || false;
    this.setState(state);
  }

  async handleSaveButton() {
    // const { currentUser } = this.props;
    const errors = this.validate();

    if (Object.keys(errors).length) {

      const message = 'Form Validation Failed. Details: \n\n' + JSON.stringify(errors);
      alert(message);

      return;
    }
    let step_copy = JSON.parse(JSON.stringify(this.state.step));
    let step_name = step_copy.step_name;
    delete step_copy.step_name;
    let new_segments = [];
    step_copy.segments.forEach((segment) => {
      segment.duration = segment.duration_hours * 60 + segment.duration_minutes;
      delete segment.duration_hours;
      delete segment.duration_minutes;
      new_segments.push(segment);
    });
    step_copy.segments = new_segments;
    const new_step = {
      profile: {
        ...step_copy
      },
      step_name: step_name,
      type: 'highheat',
      // other fields go here
    };
    try {

      let resp = await processStepRequest.postProcessStep(new_step);

      // If successful
      if (resp.status === 200) {

        // Update the state
        //let respStep = await resp.json();
        // const state = copy(this.state);
        // state.step = respStep;

        // this.setState(state);
        let newStep = await resp.json();
        this.props.postSuccessSnack('Saved successfully.');
        this.props.onSaved(newStep);
      } else {

        // Indicate error
        this.props.postErrorSnack('Error: Failed to save step.');
      }

    } catch (e) {

      // Indicate error
      this.props.postErrorSnack(`Error: Failed to connect to server. ${e}`);
    }
  }

  updateStepField(name, event) {

    const state = copy(this.state);
    let value;
    if(event.target.type === 'number') {
      value = parseFloat(event.target.value);
    } else {
      value = event.target.value;
    }
    state.step[name] = value;
    this.setState(state);
  }

  updateSegmentField(index, fieldname, event) {
    let value;
    if(event.target.type === 'number') {
      value = parseFloat(event.target.value);
    } else {
      value = event.target.value;
    }
    const state = copy(this.state);
    state.step.segments[index][fieldname] = value;
    this.updateAllSegments(state);
    this.setState(state);
  }

  render() {

    const { classes } = this.props;
    const { step } = this.state;
    const errors = this.validate(step);
    const segments = step.segments || [];

    const {ticks, total_duration, series} = extractDataPoints(segments);

    return (
      <Fragment>
        <Grid container direction='row'>
          <Grid item xs={6}>
            <TextField fullWidth
              label='Step Name'
              className={classes.textField}
              value={step.step_name || ''}
              onChange={(ev) => { this.updateStepField('step_name', ev); }}
              onBlur={(ev) => { this.updateStepField('step_name', ev); }}
              error={(errors.step_name) ? true : false} margin="normal"
            />
            <br />
            {errors.step_name ? <Typography classes={{ root: classes.error }} >{errors.step_name}</Typography> : <Fragment />}
            <br />
            {step.deleted_at
              ? <div className={classes.deleted} >&nbsp;DELETED&nbsp;</div>
              : <Fragment />}
          </Grid>
        </Grid>
        {/* --------- START PARAMETERS --------- */}
        <Grid container direction='row' alignItems='stretch' spacing={16}>
          <Grid item xs={12}>
            <Paper style={{padding: 20}}>
              <Grid container direction='row' alignItems='stretch' spacing={16}>
                <Grid item xs={3}>
                  <TextField fullWidth // className={classes.numericField}
                    label='Mass Limit'
                    type='number'
                    value={step.mass_limit || ''}
                    onChange={(ev) => { this.updateStepField('mass_limit', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Grams</InputAdornment>,
                      //classes: { input: classes.numericField },
                      inputProps: { min: 0, max: 100000}
                    }}
                    error={(errors.mass_limit) ? true : false}
                  />
                  {errors.mass_limit ? <Typography classes={{ root: classes.error }} >{errors.mass_limit}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={3}>
                  {/* <InputLabel className={classes.ringTypeLabel}>Ring Type</InputLabel> */}
                  <TextField fullWidth
                    select
                    label='Ring Type'
                    // className={classes.numericField}
                    value={step.ring_type ? step.ring_type : 'None'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    inputProps={{
                      name: 'name',
                      id: 'id',
                    }}
                    error={(errors.ring_type) ? true : false}
                    onChange={(ev) => { this.updateStepField('ring_type', ev); }} >
                    {ringTypeSuggestions.map((tmp) => {
                      return <MenuItem key={tmp.id} value={tmp.id || ''}>{tmp.label}</MenuItem>;
                    })}
                  </TextField>
                  {errors.ring_type ? <Typography classes={{ root: classes.error }} >{errors.ring_type}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth// className={classes.numericField}
                    label='Number of Rings'
                    type='number'
                    value={step.number_of_rings || ''}
                    onChange={(ev) => { this.updateStepField('number_of_rings', ev); }}
                    error={(errors.number_of_rings) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    InputProps={{
                      //classes: { input: classes.numericField },
                      inputProps: { min: 0 }
                    }}
                  />
                  {errors.number_of_rings ? <Typography classes={{ root: classes.error }} >{errors.number_of_rings}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={3}>
                  <TextField fullWidth // className={classes.numericField}
                    label='Temperature Tolerance'
                    type='number'
                    value={step.tolerance || ''}
                    onChange={(ev) => { this.updateStepField('tolerance', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{DEGREES_CELSIUS_SYMBOL}</InputAdornment>,
                      //classes: { input: classes.numericField },
                      inputProps: { min: step_fields.tolerance.min, max: step_fields.tolerance.max}
                    }}
                    error={(errors.tolerance) ? true : false}
                  />
                  {errors.tolerance ? <Typography classes={{ root: classes.error }} >{errors.tolerance}</Typography> : <Fragment />}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container direction='row' alignItems='center'>
                <Grid item xs={3}>
                  <Button variant="contained"
                    color="primary"
                    disabled={step.segments.length >= 24}
                    className={classes.button}
                    onClick={() => { this.addEmptySegment(); }}>Add New Segment
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  {errors.number_of_segments ? <Typography classes={{ root: classes.error }} >{errors.number_of_segments}</Typography> : <Fragment /> }
                  {step.segments.length >= 24 ?
                    <div className={classes.error}>Maximum of 24 Segments has been reached.</div>
                    : <Fragment />
                  }
                </Grid>
              </Grid >
              <Grid key='row7' container direction='row'>
                <Grid key='col1' item xs={1} className={classes.inputCenter}>
                            Segment&nbsp;#
                </Grid>
                <Grid key='col2' item xs={3}>
                            Segment&nbsp;Type
                </Grid>
                <Grid key='col3' item xs={2} className={classes.inputRight}>
                            Target&nbsp;Setpoint
                </Grid>
                <Grid key='col4' item xs={2} className={classes.inputRight}>
                            Ramp Rate
                </Grid>
                <Grid key='col5' item xs={2} className={classes.inputRight}>
                            Duration
                </Grid>
              </Grid>
              <Grid key='row8' container direction='row'>
                {segments.map((segment) => {
                  return <HighHeatSegmentForm
                    key={`segment-${segment.index}`}
                    segmentFields={segment_fields}
                    validateSegment={this.validateSegment}
                    className={classes.gridListTile}
                    updateSegmentField={this.updateSegmentField}
                    deleteSegmentRow={this.deleteSegmentRow}
                    step={step}
                    segment={segment} />;
                })}
              </Grid >
              <Grid key='total' container direction='row'>
                <Grid key='col1' item xs={8}></Grid>
                <Grid key='col2' item xs={2} className={classes.inputRight}>
                  {total_duration} minutes
                </Grid>
                <Grid key='col3' item xs={2}> </Grid>
              </Grid >
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Grid container direction='row'>
                <Grid key='col1' item xs={12} style={{
                  backgroundColor: '#f8f8f8',
                  border: '1px solid black',
                  // marginRight: 50,
                  margin: 20,
                  borderRadius: 12}}>
                  <ContainerDimensions>
                    {({ width }) =>
                      <Chart height={300} width={width - 80}

                        series={series}
                        // minY={0} maxY={1240}
                        minY={0}
                        maxY={1240}
                        scaleX={{
                          paddingStart: 40,
                          paddingEnd: 20
                        }}
                        scaleY={{
                          paddingTop: 40,
                          paddingBottom: 0
                        }}
                      >

                        <Title>Furname Profile Visualization</Title>
                        <Animate _ease='bounce'>
                          <Lines asAreas={false} interpolation='linear' />
                          <Dots />
                          <Ticks
                            axis='y'
                            lineLength='100%'
                            lineVisible={true}
                            label={({ index }) => series[index]}
                            lineStyle={{ stroke: 'lightgray' }}
                            labelStyle={{ textAnchor: 'end', dominantBaseline: 'middle' }}
                            labelAttributes={{ x: 65, y: -10 }}
                          />
                          <Ticks
                            ticks={ticks}
                            axis='x'
                            lineHeight='20'
                            lineLength='10'
                            lineVisible={true}
                            label={({ index }) => series[index]}
                            lineStyle={{ stroke: 'lightgray' }}
                            labelStyle={{ textAnchor: 'end', dominantBaseline: 'middle' }}
                            labelAttributes={{ y: -290 }}
                          />
                          <Labels
                            label={({ point }) => `${Math.floor(point.y)}\xB0C`}
                            dotStyle={{
                              alignmentBaseline: 'after-edge',
                              textAnchor: 'middle'
                            }}>
                          </Labels>
                        </Animate>
                        {/* https://rumble-charts.github.io/rumble-charts/#lines */}
                      </Chart>
                    }
                  </ContainerDimensions>
                </Grid>
              </Grid >
            </Paper>
          </Grid>
        </Grid>
        <Tooltip key='tt4' title={saveMessage} aria-label={saveMessage}>
          <Fab color="secondary"
            className={classes.SaveFab}
            onClick={() => { this.handleSaveButton(); }}>
            <SaveIcon style={styles.SaveIcon}
            />
          </Fab>
        </Tooltip>
      </Fragment>
    );
  }
}

HighHeatStepForm.propTypes = {
  classes: PropTypes.object.isRequired,
  step: PropTypes.object,
  postErrorSnack: PropTypes.func,
  postProcessStep: PropTypes.func,
  postSuccessSnack: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default withStyles(styles)(HighHeatStepForm);
