import { makeRequest } from './BaseRequest';

const ENDPOINT = '/inventory';

/**
 *
 * @param {string} type Material type either 'abrasive' or 'bond'.
 * @returns Returns specified material constants, otherwise returns all material constants.
 */
export async function getMaterialConstants(type = '') {
  let materialConstantsEndpoint = ENDPOINT + '/material_constants';

  // check to add query parameter
  if (type !== '')
    materialConstantsEndpoint +=`?type=${type}`;

  return makeRequest(materialConstantsEndpoint, 'GET');
}
