import React, { Component, Fragment } from 'react';
import { 
  Grid,
  TextField,
  Typography,
  LinearProgress,
  Paper,
  InputAdornment,
  Dialog,
  Slide,
  Toolbar,
  AppBar,
  IconButton,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import copy from 'fast-copy';
import * as ProcOrderRequests from '../rest/ProcessOrderRequests';
import * as ProcStepRequests from '../rest/ProcessStepRequests';
import MixingStepComponent from '../ProcessSteps/mixing-step-component';
import HighHeatStepForm from '../ProcessSteps/highheat-step-form';
import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';

const PLUS_MINUS_SYMBOL = '\xB1';

const styles = ({

  textField: {
    marginTop: 10,
    width: 400,
  },
  select: {
    width: 300,
  },
  error: {
    color: 'red'
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// function SimpleDialog(props) {
//   const { onClose, selectedValue, open } = props;

//   const handleClose = () => {
//     onClose(selectedValue);
//   };

//   const handleListItemClick = value => {
//     onClose(value);
//   };

//   return (
    
//   );
// }
// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

class OrderForm extends Component {

  constructor() {

    super();

    this.state = {
      order: {
        total_weight_tolerance: 100,
        remaining_powder_mass: 500,
        setup_powder_mass: 300,
        unit: 'grams',
      },
      errors: {},
      mixing_steps: [],
      loading_mixing_steps: false,
      highheat_steps: [],
      loading_highheat_steps: false,
      mixing_dialog: false,
      highheat_dialog: false,
      loading_total_weight: false,
      saving_process_order: false,
    };

    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.handleCreateNewHighHeatStep = this.handleCreateNewHighHeatStep.bind(this);
    this.handleCreateNewQualityCheckStep = this.handleCreateNewQualityCheckStep.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.fetchMixingSteps = this.fetchMixingSteps.bind(this);
    this.fetchHighheatSteps = this.fetchHighheatSteps.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.onMixingTemplateSaved = this.onMixingTemplateSaved.bind(this);
    this.onHighHeatTemplateSave = this.onHighHeatTemplateSave.bind(this);
  }

  componentDidMount() {
    const state = copy(this.state);
    state.mixing_wheel = <LinearProgress />;
    state.highheat_wheel = <LinearProgress />;
    this.setState(state);
    this.fetchMixingSteps();
    this.fetchHighheatSteps();
  }

  async fetchMixingSteps() {

    this.setState({
      loading_mixing_steps: true,
    });
    let steps = await this.fetchProcessSteps('mixing');

    this.setState({
      mixing_steps: steps,
      loading_mixing_steps: false,
    });

    if (steps) {

      this.props.postSuccessSnack(`Just loaded ${steps.length} mixing steps.`);

    }
  }

  async fetchHighheatSteps() {

    this.setState({
      loading_highheat_steps: true,
    });
    let steps = await this.fetchProcessSteps('highheat');

    this.setState({
      highheat_steps: steps,
      loading_highheat_steps: false,
    });

    if (steps) {

      this.props.postSuccessSnack(`Just loaded ${steps.length} highheat steps.`);

    }
  }

  async fetchProcessSteps(type) {
    try {

      let resp = await ProcStepRequests.getTemplateProcessSteps(type);

      if (resp.status === 200) {

        let steps = await resp.json();

        return steps.map((e, i) => {
          return (
            <MenuItem
              key={i}
              step_name={e.step_name}
              value={e.pk}>
              {e.step_name}
            </MenuItem>
          );
        });

      }

      // Consider other responses to be errors
      this.props.postErrorSnack(`Error: Could not load ${type} steps.`);

    } catch (e) {

      // Error executing fetch
      this.props.postErrorSnack(`Error: Could not load ${type} steps.`);
    }
  }

  handleDialogClose(type) {
    const state = copy(this.state);

    if (type === 'mixing') {
      state.mixing_dialog = false;
    }
    else if (type === 'highheat') {
      state.highheat_dialog = false;
    }
    
    this.setState(state);
  }

  updateOrderField(name, event) {
    const state = copy(this.state);

    if (event.target.type === 'number') {
      state.order[name] = parseFloat(event.target.value);
    }
    else {
      state.order[name] = event.target.value;
    }
    

    if (
      name === 'number_of_layers' ||
      name === 'setup_powder_mass' ||
      name === 'part_height' ||
      name === 'remaining_powder_mass' ||
      name === 'part_height'
    ) {
      this.setState(state, () => {
        this.calculateTotalWeight(this.state.order);
      });
    } else {
      this.setState(state);
    }
  }

  async calculateTotalWeight(order) {
    if (
      order.mixing_template_step_id !== '' &&
          order.number_of_layers !== '' && !isNaN(order.number_of_layers) &&
          order.part_height !== '' && !isNaN(order.part_height) &&
          order.setup_powder_mass !== '' && !isNaN(order.setup_powder_mass) &&
          order.remaining_powder_mass !== '' && !isNaN(order.remaining_powder_mass) ) {
      this.setState({loading_total_weight: true});
      let resp = await ProcOrderRequests.getTargetTotalMass(
        order.mixing_template_step_id,
        parseFloat(order.number_of_layers),
        parseFloat(order.part_height),
        parseFloat(order.setup_powder_mass),
        parseFloat(order.remaining_powder_mass)
      );
      this.state.order.total_weight = await resp.json();
      this.state.loading_total_weight = false;
      this.setState(this.state);
    }
  }

  validate(order) {
    const validations = {
      name: {
        type: 'string',
        name: 'Process Order Name',
        minLength: 4,
      },
      mixing_template_step_id: {
        type: 'select',
        name: 'Mixing Step',
        cannot_be: [0, 1],
      },
      highheat_template_step_id: {
        type: 'select',
        name: 'High Heat Step',
        cannot_be: [0, 1],
      },
      part_quantity: {
        type: 'number',
        name: 'Parts Requested',
        min: 1,
        max: 999999999,
      },
      number_of_layers: {
        type: 'number',
        name: 'Number of Layers',
        min: 1,
        max: 999999999,
      },
      setup_powder_mass: {
        type: 'number',
        name: 'Setup Powder Required',
        min: 1,
        max: 999999999,
      },
      part_height: {
        type: 'number',
        name: 'Part Height',
        min: 1,
        max: 999999999,
      },
      remaining_powder_mass: {
        type: 'number',
        name: 'Reserve Powder Required',
        min: 0,
        max: 999999999,
      },
      total_height: {
        type: 'number',
        name: 'Total Height',
        min: 1,
        max: 999999999,
      },
      vertical_spacing: {
        type: 'number',
        name: 'Vertical Spacing',
        min: 1,
        max: 999999999,
      },
      total_weight_tolerance: {
        type: 'number',
        name: 'Total Weight Tolerance',
        min: 0,
        max: 999999999,
      }
    };

    const errors = {};

    Object.keys(validations).forEach((field) => {
      switch(validations[field].type) {
      case 'number':

        if (validations[field].min && order[field] < validations[field].min) {
          errors[field] = `${validations[field].name} must be ${validations[field].min} or higher.`;
        }

        if (validations[field].max && order[field] > validations[field].max) {
          errors[field] = `${validations[field].name} must be ${validations[field].max} or lower.`;
        }

        if (typeof order[field] === 'undefined' || order[field] === '' || isNaN(order[field])) {
          errors[field] = `${validations[field].name} must have a numeric value.`;
        }
        break;
      case 'string':
        if (typeof order[field] === 'undefined' || order[field].length < validations[field].minLength) {
          errors[field] = `${validations[field].name} must be at least ${validations[field].minLength} characters long.`;
        }
        break;
      case 'select':
        validations[field].cannot_be.forEach((element) => {
          if (typeof order[field] === 'undefined' || element === order[field]){
            errors[field] = `${validations[field].name} is required.`;
          }
        });
        break;
      default:
        break;
      }
    });

    return errors;
  }

  async handleSaveButton() {

    if (this.loading_total_weight === true) {
      this.props.postWarningSnack('Please wait for total weight to be calculated.');
      return;
    }

    let errors = this.validate(this.state.order);

    if (Object.entries(errors).length === 0 && errors.constructor === Object) {
      this.setState({saving_process_order: true});
      let order = copy(this.state.order);

      // Remove any keys that contain empty strings.
      Object.keys(order).forEach((key) => (order[key] === '') && delete order[key]);

      let resp = await ProcOrderRequests.postProcessOrder(order);

      if (resp.status === 200) {
        let newOrder = await resp.json();
        this.props.history.push(`/app/processOrderOverview/${newOrder.pk}`);
      } else if (resp.status === 409) {
        let response = await resp.json();
        this.props.postErrorSnack(`Conflict Error: ${response}`);
      }
      else {
        let response = await resp.json();
        this.props.postErrorSnack(`Saving Error: ${response}`);
        // TODO: Error handling
      }
    } else {
      this.props.postErrorSnack('Cannot Save - Make sure all needed fields are filled.');
    }
    this.setState({saving_process_order: false});
  }

  onMixingTemplateSaved(new_step) {
    const state = copy(this.state);
    state.order.mixing_template_step_id = new_step.pk;
    state.mixing_steps.push(<MenuItem
      key={state.mixing_steps.length}
      step_name={new_step.step_name}
      value={new_step.pk}>
      {new_step.step_name}
    </MenuItem>
    );
    state.mixing_dialog = false;
    this.setState(state);
  }

  onHighHeatTemplateSave(new_step) {
    const state = copy(this.state);
    state.order.highheat_template_step_id = new_step.pk;
    state.highheat_steps.push(<MenuItem
      key={state.highheat_steps.length}
      step_name={new_step.step_name}
      value={new_step.pk}>
      {new_step.step_name}
    </MenuItem>
    );
    state.highheat_dialog = false;
    this.setState(state);
  }

  handleCreateNewHighHeatStep() {

    this.props.history.push('/app/processSteps/add/highheat/');
  }

  handleCreateNewQualityCheckStep() {

    this.props.history.push('/app/processSteps/add/qualitycheck/');
  }

  handleCancelButton() {

    this.props.history.push('/');
  }

  handleStepChange(name, event) {
    const state = copy(this.state);
    switch (name) {
    case 'mixing_template_step_id':
      if (event.target.value === 1) {
        state.mixing_dialog = true;
      } else {
        state.order.mixing_template_step_id = event.target.value;
      }
      break;
    case 'highheat_template_step_id':
      if (event.target.value === 1) {
        state.highheat_dialog = true;
      } else {
        state.order.highheat_template_step_id = event.target.value;
      }
      break;
    default:
      break;
    }
    if (name === 'mixing_template_step_id') {
      this.setState(state, () => {
        this.calculateTotalWeight(this.state.order);
      });
    } else {
      this.setState(state);
    }
  }

  render() {
    const { classes } = this.props;
    const { 
      order,
      mixing_dialog,
      highheat_dialog,
      loading_highheat_steps,
      loading_mixing_steps,
      loading_total_weight,
      saving_process_order,
    } = this.state;
    const errors = this.validate(order);

    return (
      <Fragment>
        <Grid
          container
          direction='row'
          alignItems='stretch'
          spacing={16}
        >
          <Grid item xs={12}>
            <Paper style={{padding: 20, justify: 'center' }}>
              <Grid
                container
                direction='row'
                alignItems='stretch'
                spacing={16}
              >
                <Grid item xs={12}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto'}}>
                    <Typography variant='h6'>
                      Process Order Specification
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="name"
                    label='Process Order Name'
                    type="text"
                    variant="outlined"
                    // className={classes.textField}
                    value={typeof order.name === 'undefined' ? '' : order.name}
                    onChange={(ev) => { this.updateOrderField('name', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors.name ? true : false}
                  />
                  {errors.name ? <Typography classes={{ root: classes.error }} >{errors.name}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type='date'
                    id="promise_date"
                    label='Promise Date'
                    variant="outlined"
                    value={typeof order.promise_date === 'undefined' ? '' : order.promise_date}
                    helperText={order.description}
                    onChange={(ev) => { this.updateOrderField('promise_date', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={(errors.contract) ? true : false}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    id="specification_string"
                    label='Specification String'
                    type="text"
                    variant="outlined"
                    // className={classes.textField}
                    value={typeof order.specification_string === 'undefined' ? '' : order.specification_string}
                    onChange={(ev) => { this.updateOrderField('specification_string', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors.specification_string ? true : false}
                  />
                  {errors.specification_string ? <Typography classes={{ root: classes.error }} >{errors.name}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label='Mixing Step'
                    variant="outlined"
                    value={typeof order.mixing_template_step_id === 'undefined' ? 0 : order.mixing_template_step_id}
                    onChange={(event) => { this.handleStepChange('mixing_template_step_id', event); }}
                    inputProps={{
                      name: 'Mixing Step',
                      id: 'mixingStep',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors.mixing_template_step_id ? true : false}
                    fullWidth>
                    <MenuItem value={0}>
                      Not Selected
                    </MenuItem>
                    {this.state.mixing_steps}
                    <MenuItem value={1} style={{'backgroundColor': '#F8F8F8'}}>
                      Create New...
                    </MenuItem>
                  </TextField>
                  {loading_mixing_steps ? <LinearProgress/> : <Fragment/>}
                  {errors.mixing_template_step_id ? <Typography classes={{ root: classes.error }} >{errors.mixing_template_step_id}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    label='High Heat Step'
                    variant="outlined"
                    value={typeof order.highheat_template_step_id === 'undefined' ? 0 : order.highheat_template_step_id}
                    onChange={(event) => { this.handleStepChange('highheat_template_step_id', event); }}
                    inputProps={{
                      name: 'High Heat Step',
                      id: 'highHeatStep',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors.highheat_template_step_id ? true : false}
                    fullWidth>
                    <MenuItem value={0}>
                      Not Selected
                    </MenuItem>
                    {this.state.highheat_steps}
                    <MenuItem value={1} style={{'backgroundColor': '#F8F8F8'}}>
                      Create New...
                    </MenuItem>
                  </TextField>
                  {loading_highheat_steps ? <LinearProgress/> : <Fragment/>}
                  {errors.highheat_template_step_id ? <Typography classes={{ root: classes.error }} >{errors.highheat_template_step_id}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    select
                    disabled
                    label='Quality Check Step'
                    variant="outlined"
                    value={typeof order.qualitycheck_step === 'undefined' ? 0 : order.qualitycheck_step}
                    onChange={(event) => { this.handleStepChange('qualitycheck_step', event); }}
                    inputProps={{
                      name: 'Quality Check Step',
                      id: 'qualityCheckStep',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors.qualitycheck_step ? true : false}
                    fullWidth>
                    <MenuItem value={0}>
                      Not Applicable
                    </MenuItem>
                    {/* this.state.highheat_steps */}
                    <MenuItem value={1} style={{'backgroundColor': '#F8F8F8'}}>
                      Create New...
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}> 
            <Paper style={{padding: 20, justify: 'center' }}>
              <Grid
                container
                direction='row'
                alignItems='stretch'
                spacing={16}
              >
                <Grid item xs={12}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto'}}>
                    <Typography variant='h6'>
                        Part Specification
                    </Typography>
                  </div>
                </Grid>             
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="part_quantity"
                    label='# Parts Requested'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_quantity === 'undefined' ? '' : order.part_quantity}
                    onChange={(ev) => { this.updateOrderField('part_quantity', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 0, max: 999999999 }
                    }}
                    error={(errors.part_quantity) ? true : false}
                  />
                  {errors.part_quantity ? <Typography classes={{ root: classes.error }} >{errors.part_quantity}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    type='text'
                    id="cad_file_name"
                    label='CAD File Name'
                    variant="outlined"
                    value={typeof order.cad_file_name === 'undefined' ? '' : order.cad_file_name}
                    onChange={(ev) => { this.updateOrderField('cad_file_name', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_id"
                    label='Part Inner Diameter'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_id === 'undefined' ? '' : order.part_id}
                    onChange={(ev) => { this.updateOrderField('part_id', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_id_tolerance"
                    label='Tolerance'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_id_tolerance === 'undefined' ? '' : order.part_id_tolerance}
                    onChange={(ev) => { this.updateOrderField('part_id_tolerance', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      startAdornment: <InputAdornment position="start">{PLUS_MINUS_SYMBOL}</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_od"
                    label='Part Outer Diameter'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_od === 'undefined' ? '' : order.part_od}
                    onChange={(ev) => { this.updateOrderField('part_od', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_od_tolerance"
                    label='Tolerance'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_od_tolerance === 'undefined' ? '' : order.part_od_tolerance}
                    onChange={(ev) => { this.updateOrderField('part_od_tolerance', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      startAdornment: <InputAdornment position="start">{PLUS_MINUS_SYMBOL}</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_height"
                    label='Part Height'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_height === 'undefined' ? '' : order.part_height}
                    error={errors.part_height ? true : false}
                    onChange={(ev) => { this.updateOrderField('part_height', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.part_height ? <Typography classes={{ root: classes.error }} >{errors.part_height}</Typography> : <Fragment />}

                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_height_tolerance"
                    label='Tolerance'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_height_tolerance === 'undefined' ? '' : order.part_height_tolerance}
                    onChange={(ev) => { this.updateOrderField('part_height_tolerance', ev); }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      startAdornment: <InputAdornment position="start">{PLUS_MINUS_SYMBOL}</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper style={{padding: 20, justify: 'center' }}>
              <Grid container
                direction='row'
                alignItems='stretch'
                spacing={16}
              >
                <Grid item xs={12}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto'}}>
                    <Typography variant='h6'>
                      Mixture Calculator
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="setup_powder_mass"
                    label='Startup Powder Required'
                    type="number"
                    variant="outlined"
                    value={typeof order.setup_powder_mass === 'undefined' ? '' : order.setup_powder_mass}
                    onChange={(ev) => { this.updateOrderField('setup_powder_mass', ev); }}
                    error={(errors.setup_powder_mass) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.setup_powder_mass ? <Typography classes={{ root: classes.error }} >{errors.setup_powder_mass}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="remaining_powder_mass"
                    label='Reserve Powder Required'
                    type="number"
                    variant="outlined"
                    value={typeof order.remaining_powder_mass === 'undefined' ? '' : order.remaining_powder_mass}
                    helperText={order.description}
                    onChange={(ev) => { this.updateOrderField('remaining_powder_mass', ev); }}
                    error={(errors.remaining_powder_mass) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.remaining_powder_mass ? <Typography classes={{ root: classes.error }} >{errors.remaining_powder_mass}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="part_height"
                    label='Part Height'
                    type="number"
                    variant="outlined"
                    value={typeof order.part_height === 'undefined' ? '' : order.part_height}
                    onChange={(ev) => { this.updateOrderField('part_height', ev); }}
                    error={(errors.part_height) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.part_height ? <Typography classes={{ root: classes.error }} >{errors.part_height}</Typography> : <Fragment />}

                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="number_of_layers"
                    label='Number of Layers'
                    type="number"
                    variant="outlined"
                    value={typeof order.number_of_layers === 'undefined' ? '' : order.number_of_layers}
                    onChange={(ev) => { this.updateOrderField('number_of_layers', ev); }}
                    error={(errors.number_of_layers) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.number_of_layers ? <Typography classes={{ root: classes.error }} >{errors.number_of_layers}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="total_height"
                    label='Total Height'
                    type="number"
                    variant="outlined"
                    value={typeof order.total_height === 'undefined' ? '' : order.total_height}
                    onChange={(ev) => { this.updateOrderField('total_height', ev); }}
                    error={(errors.total_height) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.total_height ? <Typography classes={{ root: classes.error }} >{errors.total_height}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="vertical_spacing"
                    label='Vertical Spacing'
                    type="number"
                    variant="outlined"
                    value={typeof order.vertical_spacing === 'undefined' ? '' : order.vertical_spacing}
                    onChange={(ev) => { this.updateOrderField('vertical_spacing', ev); }}
                    error={(errors.vertical_spacing) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.vertical_spacing ? <Typography classes={{ root: classes.error }} >{errors.vertical_spacing}</Typography> : <Fragment />}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    fullWidth
                    id="total_weight"
                    label='Total Weight'
                    type="number"
                    variant="outlined"
                    value={typeof order.total_weight === 'undefined' ? '' : order.total_weight}
                    onChange={(ev) => { this.updateOrderField('total_weight', ev); }}
                    error={(errors.total_weight) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {loading_total_weight ? <LinearProgress/> : <Fragment/>}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="total_weight_tolerance"
                    label='Total Weight Tolerance'
                    type="number"
                    variant="outlined"
                    value={typeof order.total_weight_tolerance === 'undefined' ? '' : order.total_weight_tolerance}
                    onChange={(ev) => { this.updateOrderField('total_weight_tolerance', ev); }}
                    error={(errors.total_weight_tolerance) ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">g</InputAdornment>,
                      startAdornment: <InputAdornment position="start">{PLUS_MINUS_SYMBOL}</InputAdornment>,
                      inputProps: { min: 0, max: 999999999 }
                    }}
                  />
                  {errors.total_weight_tolerance ? <Typography classes={{ root: classes.error }} >{errors.total_weight_tolerance}</Typography> : <Fragment />}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {saving_process_order ? <LinearProgress/> : <Fragment/>}
            <Paper style={{padding: 20, justify: 'center' }}>
              <Grid
                container
                direction='row'
                alignItems='center'
                justify='center'
                spacing={16}
              >
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => { this.handleCancelButton(); }}>
                            Cancel
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => { this.handleSaveButton(); }}>
                            Save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          onClose={() => {this.handleDialogClose('mixing'); }}
          aria-labelledby="mixing-step-dialog-title"
          open={mixing_dialog}
          fullWidth
          maxWidth='xl' 
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => {this.handleDialogClose('mixing'); }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <div style={{
                margin: 'auto'
              }}>
                <Typography variant='h6' style={{color: 'white'}}>
                  Create Mixing Step
                </Typography>      
              </div>
            </Toolbar>
          </AppBar>
          <div style={{
            justify: 'center',
            padding: 15
          }}>
            <MixingStepComponent
              action='add'
              stepType='mixing'
              {...this.props}
              onSaved={this.onMixingTemplateSaved}
            />
          </div>
        </Dialog>
        <Dialog
          onClose={() => {this.handleDialogClose('highheat'); }}
          aria-labelledby="highheat-step-dialog-title"
          open={highheat_dialog}
          fullWidth
          maxWidth='xl' 
          TransitionComponent={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => {this.handleDialogClose('highheat'); }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <div style={{
                margin: 'auto'
              }}>
                <Typography variant='h6' style={{color: 'white'}}>
                Create High Heat Step
                </Typography>      
              </div>
            </Toolbar>
          </AppBar>
          <div style={{
            justify: 'center',
            padding: 15
          }}>
            <HighHeatStepForm
              action='add'
              stepType='highheat'
              {...this.props}
              onSaved={this.onHighHeatTemplateSave}
            />
          </div>
        </Dialog>
      </Fragment>
    );
  }
}
OrderForm.propTypes = {
  history: PropTypes.object.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  postWarningSnack: PropTypes.func.isRequired,
  postErrorSnack: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  handleSaveButton: PropTypes.func.isRequired,
};
export default withStyles(styles)(OrderForm);