import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import {
  AppBar, IconButton, Typography, Hidden,
  Drawer, CssBaseline, MenuList, MenuItem, Toolbar, Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { authService } from '../../service/auth.service';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    padding: 0,
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: 64,
    background: '#EEEEEE',
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: theme.palette.background.default,
    marginTop: 20,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    height: '100%',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class Layout extends Component {

  constructor() {

    super();

    this.state = {
      mobileOpen: false
    };

    this.handleHomeMenuItem = this.handleHomeMenuItem.bind(this);
    this.handleAddProcessOrder = this.handleAddProcessOrder.bind(this);
    this.handleViewProcessOrders = this.handleViewProcessOrders.bind(this);
    this.handleHighHeatStepTable = this.handleHighHeatStepTable.bind(this);
    this.handleMixingStepTable = this.handleMixingStepTable.bind(this);
    this.handleScaleFactor = this.handleScaleFactor.bind(this);
    this.handleMaterialFormulation = this.handleMaterialFormulation.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  handleHomeMenuItem() {
    this.props.history.push('/');
  }

  handleAddProcessOrder() {
    this.props.history.push('/app/orders/add/');
  }

  handleViewAddProcessOrderPartRoughness = () => {
    this.props.history.push('/app/orders/add/part-roughness');
  }

  handleViewProcessOrders() {
    this.props.history.push('/app/orders/');
  }

  handleScaleFactor() {
    this.props.history.push('/app/data-analysis/scale-factor');
  }

  handleMaterialFormulation() {
    this.props.history.push('/app/data-analysis/material-formulation');
  }

  handleHighHeatStepTable() {
    this.props.history.push('/app/processSteps/highheatsteptable');
  }

  handleMixingStepTable() {
    this.props.history.push('/app/processSteps/mixingsteptable');
  }

  render() {
    const { classes, children } = this.props;
    const { mobileOpen } = this.state;

    const drawer = (
      <div>
        <MenuList>
          <MenuItem
            onClick={this.handleHomeMenuItem}>
            <b>Home</b>
          </MenuItem>
          <MenuItem
            onClick={authService.login}>
            <b>Login</b>
          </MenuItem>
          <MenuItem>
            <b>Process Orders</b>
          </MenuItem>
          <MenuItem
            onClick={this.handleAddProcessOrder}>
            &nbsp;&nbsp;&nbsp;&nbsp;Create New Order
          </MenuItem>
          <MenuItem
            onClick={this.handleViewProcessOrders}>
            &nbsp;&nbsp;&nbsp;&nbsp;Review Existing Orders
          </MenuItem>
          <MenuItem
            onClick={this.handleViewAddProcessOrderPartRoughness}>
            &nbsp;&nbsp;&nbsp;&nbsp;Add Part Roughness
          </MenuItem>
          <MenuItem>
            <b>Process Steps</b>
          </MenuItem>
          <MenuItem onClick={this.handleHighHeatStepTable}>
            &nbsp;&nbsp;&nbsp;&nbsp;High Heat Steps
          </MenuItem>
          <MenuItem onClick={this.handleMixingStepTable}>
            &nbsp;&nbsp;&nbsp;&nbsp;Mixing Steps
          </MenuItem>
          <MenuItem>
            <b>Data Analysis</b>
          </MenuItem>
          <MenuItem onClick={this.handleScaleFactor}>
            &nbsp;&nbsp;&nbsp;&nbsp; Scale Factor
          </MenuItem>
          <MenuItem onClick={this.handleMaterialFormulation}>
            &nbsp;&nbsp;&nbsp;&nbsp; Material Formulation
          </MenuItem>
        </MenuList>
      </div>
    );

    return (
      <Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar position="absolute" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}>
                <Menu />
              </IconButton>
              <Typography variant="title" color="inherit" noWrap>
                {this.props.title}
              </Typography>
            </Toolbar>
          </AppBar>
          <Hidden>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              onClick={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container justify="center" style={{paddingBottom: 20}}>
              <Grid item xs={12} md={10}>
                {children}
              </Grid>
            </Grid>
          </main>
        </div>
      </Fragment>);
  }
}

Layout.propTypes = {
  processSteps: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};


export default compose(
  withRouter,
  withStyles(styles)
)(Layout);
