import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import { getProcessOrders, deleteProcessOrder } from '../rest/ProcessOrderRequests';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DeleteConfirmationDialog from '../Dialogs/delete-confirmation-dialog';

class OrderTable extends Component {

  constructor() {
    super();

    this.state = {
      data: [],
      isLoading: false,
      deleteDialogOpen: false,
      deletePending: false,
      orderToDelete: null,
    };

    this.fetchProcessOrders = this.fetchProcessOrders.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this);
  }

  componentDidMount() {
    this.fetchProcessOrders();
  }

  async fetchProcessOrders() {
    this.setState({ isLoading: true });

    try {
      let resp = await getProcessOrders();

      if (resp.status === 200) {
        let orders = await resp.json();

        this.setState({
          data: orders,
          isLoading: false,
        });
      } else {
        // Consider other responses to be errors
        this.props.postErrorSnack('Error: Could not load process orders.');
        this.setState({ isLoading: false });
      }

    } catch (e) {
      this.props.postErrorSnack('Error: Could not connect to server.');
      this.setState({ isLoading: false });
    }
  }

  /**
   * Closes the delete confirmation dialog.
   */
  closeDeleteDialog() {

    this.setState({
      deleteDialogOpen: false,
      orderToDelete: null
    });
  }

  /**
   * Opens the delete confirmation dialog.
   */
  openDeleteDialog(pk, tableMeta) {

    const orderToDelete = {
      pk: pk,
      name: tableMeta.rowData[0],
    };

    this.setState({
      deleteDialogOpen: true,
      orderToDelete: orderToDelete
    });
  }

  /**
   * Handles deleting the process order and
   * refreshing the table.
   */
  async handleDeleteConfirmed() {

    this.setState({ deletePending: true });

    try {

      const resp = await deleteProcessOrder(this.state.orderToDelete.pk);

      if (resp.status === 200) {

        // Indicate success & reload the table
        this.props.postSuccessSnack('Process order deleted');
        this.fetchProcessOrders();

      } else {

        this.props.postErrorSnack('Error: Failed to delete process order');
      }

    } catch (e) {

      this.props.postErrorSnack('Error: Failed to delete process order');

    } finally {

      this.setState({ deletePending: false });
      this.closeDeleteDialog();
    }
  }

  render() {
    const columns = [
      {
        name: 'name',
        label: 'Order Name',
      },
      {
        name: 'promise_date',
        label: 'Promise Date',
      },
      {
        name: 'status',
        label: 'Order Status',
      },
      {
        name: 'pk',
        label: 'Detailed View',
        options: {
          filter: false,
          customBodyRender: (value) => {
            return (
              <Link to={`/app/processOrderOverview/${value}`}>Details</Link>
            );
          }
        }
      },
      {
        name: 'pk',
        label: 'Delete',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <IconButton onClick={() => this.openDeleteDialog(value, tableMeta)} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            );
          }
        }
      },
    ];

    const options = {
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isLoading ? 'Loading process orders...' : 'Sorry, no matching records found',
        }
      }
    };

    return (
      <React.Fragment>
        <MUIDataTable
          title={'Process Orders'}
          data={this.state.data}
          columns={columns}
          options={options}
        />
        <DeleteConfirmationDialog
          open={this.state.deleteDialogOpen}
          handleClose={this.closeDeleteDialog}
          handleDeleteConfirmed={this.handleDeleteConfirmed}
          title={'Are you sure you want to delete this process order?'}
          text={this.state.orderToDelete ? this.state.orderToDelete.name : ''}
          deletePending={this.state.deletePending}
        />
      </React.Fragment>
    );
  }
}

OrderTable.propTypes = {
  postSuccessSnack: PropTypes.func.isRequired,
  postErrorSnack: PropTypes.func.isRequired
};

export default OrderTable;