import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default class ErrorsDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      open: true
    };
    // this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  render() {
    const errors = this.props.errors || [];
    if(!errors.length)
      return <Fragment />;
    return (
      <div id='ErrorsDialog'>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Error Report</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The following errors were encountered:
              <br/>
              <ul>
                {errors.map( (err, key) => { return <li key={key} >{err}</li>; })}
              </ul>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Acknowledge
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ErrorsDialog.propTypes = {
  errors: PropTypes.array
};