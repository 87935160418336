import {
  makeRequest,
  toQueryString
} from './BaseRequest';

const ENDPOINT = '/process_order';

export function getProcessOrder(process_order_id) {
  return makeRequest(`${ENDPOINT}?process_order_id=${process_order_id}`, 'GET');
}

export function getProcessOrders() {

  return makeRequest(ENDPOINT, 'GET');
}

export function postProcessOrder(processOrder) {

  return makeRequest(ENDPOINT, 'POST', processOrder);
}

/**
 * Deletes a process order with the matching primary key.
 * @param {String} pk The primary key of the process order to delete.
 */
export function deleteProcessOrder(pk) {

  return makeRequest(ENDPOINT, 'DELETE', {
    'process_order_id': pk
  });
}

export function submitOrder(process_order_id) {
  return makeRequest(ENDPOINT + '/' + process_order_id + '/submit', 'POST');
}

export function getProcessOrderForCSV(process_order_id) {
  return makeRequest(`${ENDPOINT}?process_order_id=${process_order_id}&csv=true`, 'GET');
}
/*
    type: 'abrasive' or 'bond', default is all types
    return: list of material constants
/**
 * Gets the target mix density for a process order form process order API.
 * @param {string} abrasive             Abrasive material component of mix.
 * @param {string} abrasive_percentage  Abrasive material percentage of mix.
 * @param {string} bond                 Bond material component of mix.
 * @param {string} bond_percentage      Bond material percentage of mix.
 * @returns {Promise<Response>} Target mix density for process order.
 */
export function getTargetMixDensity(abrasive, abrasive_percentage, bond, bond_percentage) {
  let queryParameters = {
    'abrasive': abrasive,
    'abrasive_percentage': abrasive_percentage,
    'bond': bond,
    'bond_percentage': bond_percentage
  };
  let targetMixDensityEndpoint = ENDPOINT + '/target_density';
  targetMixDensityEndpoint += '?' + toQueryString(queryParameters);

  return makeRequest(targetMixDensityEndpoint, 'GET');
}

/**
 * Gets target total mass from the process order api.
 * @param {string} template_step_id         Process step template step id.
 * @param {string} number_of_layers         Number of print layers.
 * @param {string} part_height              Part height in mm.
 * @param {string} setup_powder_mass        Setup powder mass in grams.
 * @param {string} remaining_powder_mass    Remaining powder mass in grams.
 * @returns {Promise<Response>} Return rest request.
 */
export function getTargetTotalMass(template_step_id, number_of_layers, part_height, setup_powder_mass, remaining_powder_mass) {
  let queryParameters = {
    'template_step_id': template_step_id,
    'number_of_layers': number_of_layers,
    'part_height': part_height,
    'setup_powder_mass': setup_powder_mass,
    'remaining_powder_mass': remaining_powder_mass
  };
  let totalMassEndpoint = ENDPOINT + '/target_total_mass?' + toQueryString(queryParameters);
  return makeRequest(totalMassEndpoint, 'GET');
}