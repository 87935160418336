import React, { Component, Fragment } from 'react';
import { Grid, InputAdornment, MenuItem, TextField, Typography, Button, LinearProgress } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import copy from 'fast-copy';
import {
  ProcessSteps,
  MIXING_STEP,
  formatTargetMixDensity
} from './data/process-steps';
import PropTypes from 'prop-types';
import * as processStepRequest from '../rest/ProcessStepRequests';

import * as InventoryRequests from '../rest/InventoryRequests';
import * as ProcessOrderRequests from '../rest/ProcessOrderRequests';


const mixingParameters = ProcessSteps.filter((step) => { return step.stepType === MIXING_STEP; })[0];

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  item: {
    marginLeft: 48,
    paddingLeft: 40
  },
  SaveFab: {
    right: 18,
    bottom: 40,
    position: 'fixed',
    backgroundColor: theme.palette.text.secondary,
  },
  InputLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '80%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  leftItem: {
    textAlign: 'left',
    marginLeft: 40,
    paddingLeft: 40,
    minWidth: 240
  },
  error: {
    color: 'red',
    fontSize: '70%'
  },
  rightItem: {
    textAlign: 'left',
    marginRight: 40,
    minWidth: 240
  },
  Fab: {
    backgroundColor: theme.palette.text.secondary
  },
  inputRight: {
    textAlign: 'right'
  },
  textField: {
    width: '90%'
  },
  numericField: {
    width: '90%'
  },
  select: {
    width: '90%'
  },
  deleted: {
    textAlign: 'center',
    fontFamily: 'Tahoma',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#CC0000'
  },
  errorSnack: {
    backgroundColor: theme.palette.error.dark,
  },
  successSnack: {
    backgroundColor: green[600],
  },
});

const sortLabels = (a, b) => {
  var labelA = a.label.toUpperCase(); // ignore upper and lowercase
  var labelB = b.label.toUpperCase(); // ignore upper and lowercase
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  // labels must be equal
  return 0;
};

class MixingStepComponent extends Component {

  constructor(props) {
    super(props);

    window.mixingStepFormTest = this;
    window.mixingStepFormTestResults = {};

    this.findParametersForStep = this.findParametersForStep.bind(this);
    this.updateStepField = this.updateStepField.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
  }

  UNSAFE_componentWillMount() {
    let step = {
      step_name: '',
      type: mixingParameters.stepType,
    };

    this.parameters = {};

    // Read in all of the parameters for mixing
    mixingParameters.parameters.forEach(parameter => {

      if (parameter.default != null) {
        step[parameter.tag] = parameter.default;
      } else {
        step[parameter.tag] = '';
      }

      this.parameters[parameter.tag] = parameter;
    });
    step.mix_time_units = this.parameters.mix_time.uom;
    step.density_mass_unit = this.parameters.density_sample_mass.uom;

    let state = {
      step: step,
      errors: {},
      loading_target_density: false,
      gritTypeSuggestions: [{ label: 'Loading...', id: '0' }],
      fritTypeSuggestions: [{ label: 'Loading...', id: '0' }]
    };

    // if (this.props.step) {
    //   state.step = this.props.step;
    // }

    this.setState(state);
    this.populateMaterials();
  }

  handleMaterialFetchClose = () => {
    this.setState({
      showRequestError: false
    });
  }

  async handleSaveButton(step) {
    const errors = this.validate(step);

    // If validation fails
    if (Object.keys(errors).length) {
      return;
    }
    if (this.state.loading_target_density) {
      this.props.postWarningSnack('Please wait for target mix density to be calculated.');
      return;
    }

    try {
      console.log('Saving:', this.state.step);
      let resp = await processStepRequest.postProcessStep(this.state.step);

      // If successful 
      if (resp.status === 200) {

        // Update the state
        let newStep = await resp.json();
        const state = copy(this.state);
        state.step = newStep;

        this.setState(state);
        this.props.postSuccessSnack('Process step saved.');
        this.props.onSaved(state.step);
      } else {

        // Indicate error
        this.props.postErrorSnack('Non 200 response.');
      }
    } catch (e) {
      // Indicate error
      console.log(e);
      this.props.postErrorSnack('Failed to save.');
    }
  }

  updateStepField(name, event) {

    const state = copy(this.state);

    state.step[name] = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value;

    if (name === 'target_abrasive_pct') {
      try {
        let pc = parseFloat(event.target.value);

        if (pc >= this.parameters.target_abrasive_pct.min && pc <= this.parameters.target_abrasive_pct.max) {
          state.step.target_bond_pct = 100.0 - pc;
        }
      } catch (ex) {
        console.log(ex);
      }

    }
    else if (name === 'target_bond_pct') {
      try {
        let pc = parseFloat(event.target.value);

        if (pc >= this.parameters.target_bond_pct.min && pc <= this.parameters.target_bond_pct.max) {
          state.step.target_abrasive_pct = 100.0 - pc;
        }
      } catch (ex) {
        console.log(ex);
      }
    }
    this.setState(state);
    if (
      name === 'target_abrasive_pct' || 
      name === 'abrasive_type' ||
      name === 'bond_type' ||
      name === 'target_bond_pct'
    ) {
      this.getTargetDensity(state.step);
    }
  }

  async getTargetDensity(step) {
    if (!isNaN(step.target_abrasive_pct) && step.abrasive_type !== '' &&
      !isNaN(step.target_bond_pct) && step.bond_type !== '') {
      if (step.target_abrasive_pct + step.target_bond_pct === 100) {
        this.setState({loading_target_density: true});
        let resp = await ProcessOrderRequests.getTargetMixDensity(
          step.abrasive_type,
          step.target_abrasive_pct,
          step.bond_type,
          step.target_bond_pct
        );
        this.state.step.target_density = await resp.json();
        this.state.loading_target_density = false;
        this.setState(this.state);
      }
    }
  }

  async populateMaterials() {
    // get all material constants
    let resp, materials;
    try {
      resp = await InventoryRequests.getMaterialConstants();
      this.state.showRequestError = false;
    } catch (error) {
      console.log(error.name);
      this.state.showRequestError = true;
      this.setState(this.state);
      return;
    }

    materials = await resp.json();

    // filter material constants into abrasives and bonds
    let abrasive_materials = materials.filter((material) => {
      return material.type === 'abrasive';
    });
    let bond_materials = materials.filter((material) => {
      return material.type === 'bond';
    });

    // check for abrasive materials
    if (abrasive_materials.length !== 0) {
      // populate abrasive drop down
      this.setState({
        gritTypeSuggestions: abrasive_materials.map((m) => {
          let label = (m['b_number'] != null) ? `${m['pk']}-${m['b_number']}` : m['pk'];
          return { label: label, id: label };
        }).sort((a,b) => sortLabels(a, b))
      });
    } else {
      this.setState({
        gritTypeSuggestions: [{ label: 'No abrasives', id: '0' }]
      });
    }

    // check for bond materials
    if (bond_materials.length !== 0) {
      // populate bond drop down
      this.setState({
        fritTypeSuggestions: bond_materials.map((m) => {
          let label = m['pk'];
          return { label: label, id: label };
        }).sort((a,b) => sortLabels(a, b))
      });
    } else {
      this.setState({
        fritTypeSuggestions: [{ label: 'No bonds', id: '0' }]
      });
    }
  }

  findParametersForStep(stepType) {

    if (!stepType) {
      // debugger;
    }

    let selectedStep = ProcessSteps.filter((p) => {
      return p.stepType === stepType;
    });

    selectedStep = selectedStep[0];

    return selectedStep.parameters;
  }

  validate(step) {
    const errors = {};
    const parameters = this.parameters;

    if (!step.step_name) {
      errors.step_name = 'Name is required';
    } else if (step.step_name.length < 4) {
      errors.step_name = 'Name should be at least 4 characters';
    }

    // duplicate name check
    const matchesByName = this.props.processSteps.filter((s) => {
      return s.step_name === step.step_name;
    });

    if (this.props.action === 'add' && matchesByName.length && matchesByName[0].id !== step.id) {
      errors.step_name = 'Name is already used in another process step.';
    }

    // validate grit materials
    if (!step.abrasive_type.length) {
      errors.abrasive_type = 'Abrasive Material is required.';
    }

    if (!step.bond_type.length) {
      errors.bond_type = 'Bond Material is required.';
    }

    if ((step.target_abrasive_pct < this.parameters.target_abrasive_pct.min || (step.target_abrasive_pct > this.parameters.target_abrasive_pct.max))) {
      errors.target_abrasive_pct = `Abrasive Percent must be between ${parameters.target_abrasive_pct.min} and ${parameters.target_abrasive_pct.max}`;
    }

    if ((step.target_bond_pct < parameters.target_bond_pct.min || (step.target_bond_pct > parameters.target_bond_pct.max))) {
      errors.target_bond_pct = `Bond Percent must be between ${parameters.target_bond_pct.min} and ${parameters.target_bond_pct.max}`;
    }

    // validate grit materials
    const sum = (parseFloat(step.target_abrasive_pct) + parseFloat(step.target_bond_pct));

    if (!errors.target_abrasive_pct) {
      if (sum !== 100.0) {
        errors.target_abrasive_pct = errors.target_bond_pct = `Abrasive and Bond percents must<br/>add up to 100%, got ${sum} instead`;
      }
    }

    if (!errors.target_bond_pct) {
      if (sum !== 100.0) {
        errors.target_abrasive_pct = errors.target_bond_pct = `Abrasive and Bond percents must<br/>add up to 100%, got ${sum} instead`;
      }
    }

    if (step.abrasive_tol < parameters.abrasive_tol.min || (step.abrasive_tol > parameters.abrasive_tol.max)) {
      errors.abrasive_tol = `Abrasive tolerance must be between ${parameters.abrasive_tol.min} and ${parameters.abrasive_tol.max}`;
    }

    if (step.bond_tol < parameters.bond_tol.min || (step.bond_tol > parameters.bond_tol.max)) {
      errors.bond_tol = `Bond tolerance must be between ${parameters.bond_tol.min} and ${parameters.bond_tol.max}`;
    }

    if (step.density_tolerance < parameters.density_tolerance.min || (step.density_tolerance > parameters.density_tolerance.max)) {
      errors.density_tolerance = `Density tolerance must be between ${parameters.density_tolerance.min} and ${parameters.density_tolerance.max}`;
    }

    if (step.density_sample_mass < parameters.density_sample_mass.min || (step.density_sample_mass > parameters.density_sample_mass.max)) {
      errors.density_sample_mass = `Density Sample weight must be between ${parameters.density_sample_mass.min} and ${parameters.density_sample_mass.max}`;
    }

    if (step.density_sample_mass_tolerance < parameters.density_sample_mass_tolerance.min || (step.density_sample_mass_tolerance > parameters.density_sample_mass_tolerance.max)) {
      errors.density_sample_mass_tolerance = `Sample Weight tolerance must be between ${parameters.density_sample_mass_tolerance.min} and ${parameters.density_sample_mass_tolerance.max}`;
    }

    if (step.mix_time < parameters.mix_time.min || (step.mix_time > parameters.mix_time.max)) {
      errors.mix_time = `Mix Time must be between ${parameters.mix_time.min} and ${parameters.mix_time.max}`;
    }

    if (window.mixingStepFormTestResults) {
      window.mixingStepFormTestResults.errors = errors;
    }

    return errors;
  }


  render() {
    const { classes } = this.props;
    const { step, gritTypeSuggestions, fritTypeSuggestions, loading_target_density } = this.state;
    const errors = this.validate(step);

    return (
      <Fragment>
        <Grid 
          container
          direction='row'
          alignItems='stretch'
          spacing={16}
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Step Name'
              variant='outlined'
              value={step.step_name}
              onChange={(ev) => { this.updateStepField('step_name', ev); }}
              
              error={(errors.step_name) ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.step_name ? <Typography classes={{ root: classes.error }} >{errors.step_name}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Mix Time'
              type='number'
              variant='outlined'
              value={step.mix_time}
              onChange={(ev) => { this.updateStepField('mix_time', ev); }}
              error={errors.mix_time ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.mix_time.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.mix_time ? <Typography classes={{ root: classes.error }} >{errors.mix_time}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              value={step.abrasive_type}
              label='Abrasive Material ID'
              variant='outlined'
              onChange={(ev) => { this.updateStepField('abrasive_type', ev); }}
              
              inputProps={{
                name: 'abrasive_type',
                id: 'abrasive_type-id',
              }} 
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.abrasive_type ? true : false}
            >
              {gritTypeSuggestions.map((tmp) => {
                return <MenuItem key={tmp.id} value={tmp.id}>{tmp.label}</MenuItem>;
              })}
            </TextField>
            {errors.abrasive_type ? <Typography classes={{ root: classes.error }} >{errors.abrasive_type}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label='Bond Material ID'
              value={step.bond_type}
              variant='outlined'
              onChange={(ev) => { this.updateStepField('bond_type', ev); }}
              
              inputProps={{
                name: 'bond_type',
                id: 'bond_type-id',
              }} 
              InputLabelProps={{
                shrink: true,
              }}
              error={errors.bond_type ? true : false}
            >
              {fritTypeSuggestions.map((tmp) => {
                return <MenuItem key={tmp.id} value={tmp.id}>{tmp.label}</MenuItem>;
              })}
            </TextField>
            {errors.bond_type ? <Typography classes={{ root: classes.error }} >{errors.bond_type}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="target_abrasive_pct"
              label={this.parameters['target_abrasive_pct'].parameter}
              type='number'
              variant='outlined'
              value={step.target_abrasive_pct}
              onChange={(ev) => { this.updateStepField('target_abrasive_pct', ev); }}
              
              InputProps={{
                endAdornment:
                    <InputAdornment position="end">{this.parameters.target_abrasive_pct.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={(errors.target_abrasive_pct) ? true : false}
            />
            {errors.target_abrasive_pct ? <Typography classes={{ root: classes.error }} >{errors.target_abrasive_pct}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="target_bond_pct"
              label='% Weight Bond'
              type='number'
              variant='outlined'
              value={step.target_bond_pct}
              onChange={(ev) => { this.updateStepField('target_bond_pct', ev); }}
              
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.target_bond_pct.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={(errors.target_bond_pct) ? true : false}
            />
            {errors.target_bond_pct ? <Typography classes={{ root: classes.error }} >{errors.target_bond_pct}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              id="abrasive_tol"
              label={this.parameters['abrasive_tol'].parameter}
              value={step.abrasive_tol}
              type='number'
              helperText={step.description}
              onChange={(ev) => { this.updateStepField('abrasive_tol', ev); }}
              error={(errors.abrasive_tol) ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.abrasive_tol.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.abrasive_tol ? <Typography classes={{ root: classes.error }} >{errors.abrasive_tol}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              id="bond_tol"
              label={this.parameters['bond_tol'].parameter}
              value={step.bond_tol}
              type='number'
              onChange={(ev) => { this.updateStepField('bond_tol', ev); }}
              error={(errors.bond_tol) ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.bond_tol.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.bond_tol ? <Typography classes={{ root: classes.error }} >{errors.bond_tol}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              id="density_sample_mass"
              label={this.parameters['density_sample_mass'].parameter}
              type='number'
              value={step.density_sample_mass}
              onChange={(ev) => { this.updateStepField('density_sample_mass', ev); }}
              error={(errors.density_sample_mass) ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.density_sample_mass.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.density_sample_mass ? <Typography classes={{ root: classes.error }} >{errors.density_sample_mass}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              id="density_sample_mass_tolerance"
              label={this.parameters['density_sample_mass_tolerance'].parameter}
              type='number'
              value={step.density_sample_mass_tolerance}
              onChange={(ev) => { this.updateStepField('density_sample_mass_tolerance', ev); }}
              error={(errors.density_sample_mass_tolerance) ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.density_sample_mass_tolerance.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.density_sample_mass_tolerance ? <Typography classes={{ root: classes.error }} >{errors.density_sample_mass_tolerance}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              disabled
              id="target_density"
              label={this.parameters['target_density'].parameter}
              value={formatTargetMixDensity(step.target_density, 3)}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.target_density.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {loading_target_density ? <LinearProgress/> : <Fragment/>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant='outlined'
              id="density_tolerance"
              label={this.parameters['density_tolerance'].parameter}
              value={step.density_tolerance}
              helperText={''}
              onChange={(ev) => { this.updateStepField('density_tolerance', ev); }}
              error={(errors.density_tolerance) ? true : false}
              InputProps={{
                endAdornment: <InputAdornment position="end">{this.parameters.density_tolerance.uom}</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {errors.density_tolerance ? <Typography classes={{ root: classes.error }} >{errors.density_tolerance}</Typography> : <Fragment />}
          </Grid>
          <Grid item xs={11}></Grid>
          <Grid item xs={1}>
            <Button
              style={{margin: 1, height: 40}}
              fullWidth
              variant='contained'
              color='primary'
              disabled={Object.keys(errors).length > 0}
              onClick={() => { this.handleSaveButton(step); }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

MixingStepComponent.propTypes = {
  postErrorSnack: PropTypes.func.isRequired,
  postWarningSnack: PropTypes.func.isRequired,
  postSuccessSnack: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired, // Call back for after the function saved.
  classes: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  processSteps: PropTypes.array.isRequired,
};

export default withStyles(styles)(MixingStepComponent);
