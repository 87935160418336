// Mixing
// Printing
// Low Heat
// Depowdering
// Green Body meas.
// High Heat
// Sintered meas.
// Cut Measurement
// QA Measurement

const MIXING_STEP = 'mixing',
  PRINTING_STEP = 'printing',
  LOWHEAT_STEP = 'lowheat',
  DEPOWDERING_STEP = 'depowerding',
  GREEN_BODY_MEAS_STEP = 'greenbodymeasurement',
  HIGH_HEAT_STEP = 'highheat',
  SINTERED_MEAS_STEP = 'sinteredmeasurement',
  CUT_MEAS_STEP = 'cutmeasurement',
  QA_MEAS_STEP = 'qameasurement',
  PROCESS_STEP_ORDER = [
    MIXING_STEP,
    PRINTING_STEP,
    LOWHEAT_STEP,
    DEPOWDERING_STEP,
    GREEN_BODY_MEAS_STEP,
    HIGH_HEAT_STEP,
    SINTERED_MEAS_STEP,
    CUT_MEAS_STEP,
    QA_MEAS_STEP
  ];

let ProcessSteps = [{
  stepType: MIXING_STEP,
  parameters: [
    {
      parameter: 'Abrasive Material ID',
      tag: 'abrasive_type',
      uom: 'Type (string)',
      description: 'This will be a list of items.  Bob will supply.  A placeholder is on the data tab.  Values in the Values column shows acceptable.',
      min: '',
      typical: '',
      max: ''
    },
    {
      parameter: 'Bond Material ID',
      tag: 'bond_type',
      uom: 'Type (string)',
      description: 'This will be a list of items.  Bob will supply.  A placeholder is on the data tab.  Values in the Values column shows acceptable.',
      min: '',
      typical: '',
      max: ''
    },
    {
      parameter: '% Weight Abrasive',
      tag: 'target_abrasive_pct',
      uom: 'wt%',
      description: '% weight of Abrasive in the mix',
      values: '',
      min: 70,
      typical: '',
      max: 90
    },
    {
      parameter: '% Weight Bond',
      tag: 'target_bond_pct',
      uom: 'wt%',
      description: '% weight of the Bond in the mix',
      values: '  ',
      min: 10,
      typical: '',
      max: 30
    },
    {
      parameter: 'Tolerance of Abrasive',
      tag: 'abrasive_tol',
      uom: 'Grams',
      description: '+/- amount allowed for mixing accuracy in Abrasive Weight',
      min: 0,
      default: 0.5,
      max: 1
    },
    {
      parameter: 'Tolerance of Bond',
      tag: 'bond_tol',
      uom: 'Grams',
      description: '+/- amount allowed for mixing accuracy in Bond Weight',
      min: 0,
      default: 0.5,
      max: 1
    },
    {
      parameter: 'Target Mix Density',
      tag: 'target_density',
      uom: 'g/cc',
      description: 'Target density of the mix. This is a calculated value. The formula and the density constants are in the comments column. The process step program needs to be able to calculate this value.  '
    },
    {
      parameter: 'Density Tolerance',
      tag: 'density_tolerance',
      uom: 'g/cc',
      description: '+/- amount allowed for mixed density',
      min: 0.001,
      default: 0.007,
      max: 0.007,
    },
    {
      parameter: 'Pycnometer Sample Weight',
      tag: 'density_sample_mass',
      uom: 'Grams',
      description: 'weight of the sample to use for density measurement',
      min: 27,
      default: 34,
      max: 43
    },
    {
      parameter: 'Pycnometer Sample Weight Tolerance',
      tag: 'density_sample_mass_tolerance',
      uom: 'Grams',
      description: 'tolerance for the weight of the sample for density measurement',
      min: 0.5,
      default: 4,
      max: 7
    },
    {
      parameter: 'Mix Time',
      tag: 'mix_time',
      uom: 'minutes',
      description: 'Time to mix in minutes',
      min: 1,
      default: 30,
      max: 60
    }
  ]
},
{
  stepType: PRINTING_STEP,
  parameters: [{
    parameter: 'CAD File',
    tag: 'cad_file_url',
    type: 'string',
    uom: 'File\u00A0Name',
    description: 'The name of the CAD file to use for the print job'
  }]
},
{
  stepType: LOWHEAT_STEP,
  parameters: []
},
{
  stepType: DEPOWDERING_STEP,
  parameters: []
},
{
  stepType: GREEN_BODY_MEAS_STEP,
  parameters: [{
    parameter: 'Part dimensions-before high heat',
    tag: 'beforeDimensions',
    uom: 'mm',
    description: '',
    min: 'varies',
    typical: 10,
    max: 'varies'
  },
  {
    parameter: 'Tolerance for above',
    tag: 'beforeDimensionsTolerance',
    uom: 'mm',
    description: '',
    min: 0.02,
    typical: 0.1,
    max: 1.0
  },
  {
    parameter: 'Part mass-before high heat',
    tag: 'beforeMass',
    uom: 'Grams',
    description: '',
    min: 'varies',
    typical: 5,
    max: 'varies'
  },
  {
    parameter: 'Tolerance for above',
    tag: 'beforeMassTolerance',
    uom: 'Grams',
    description: '',
    min: 0.01,
    typical: 0.02,
    max: 0.05
  }
  ]
},
{
  stepType: HIGH_HEAT_STEP,
  parameters: [{
    parameter: 'Temperature',
    tag: 'temperature',
    uom: 'deg\xA0C',
    description: '',
    min: 20,
    typical: 500,
    max: 1100
  },
  {
    parameter: 'Tolerance for above',
    tag: 'temperatureTolerance',
    uom: 'deg\xA0C',
    description: '',
    min: 0.5,
    typical: 1,
    max: 2
  },
  {
    parameter: 'High Heat Time',
    tag: 'time',
    uom: 'minutes',
    description: '',
    min: 0,
    typical: 500,
    max: 1200
  },
  {
    parameter: 'Tolerance for above',
    tag: 'timeTolerance',
    uom: 'minutes',
    description: '',
    min: 0.5,
    typical: 1,
    max: 5
  }
  ]
},
{
  stepType: SINTERED_MEAS_STEP,
  parameters: [{
    parameter: 'Part dimensions-after high heat',
    tag: 'dimensionsAfter',
    uom: 'mm',
    description: '',
    min: 'varies',
    typical: 10,
    max: 'varies'
  },
  {
    parameter: 'Tolerance for above',
    tag: 'dimensionsAfterTolerance',
    uom: 'mm',
    description: '',
    min: 0.02,
    typical: 0.1,
    max: ''
  },
  {
    parameter: 'parameter: Part mass-before high heat',
    tag: 'massBefore',
    uom: 'Grams',
    description: '',
    min: 'varies',
    typical: 5,
    max: 'varies'
  },
  {
    parameter: 'Tolerance for above',
    tag: 'massBeforeTolerance',
    uom: 'Grams',
    description: '',
    min: 0.01,
    typical: 0.02,
    max: 0.05
  }
  ]
},
{
  stepType: CUT_MEAS_STEP,
  parameters: []
},
{
  stepType: QA_MEAS_STEP,
  parameters: []
}
];
ProcessSteps.map((stepType) => {
  // assign UUIDs to each sub-object
  stepType.parameters.map((p) => {
    // p.id = uuid();
    return p;
  });
  // stepType.id = uuid();
  return stepType;
});

const formatTargetMixDensity = (val, precision) => {
  val = parseFloat(val);
  let ret = '' + val.toFixed(precision);
  return isNaN(ret) ? '' : ret;
};

const HIGH_HEAT_RING_TYPES = [
  'None',
  'ptcr-eth(850-1100)',
  'ptcr-uth(660-900)'
];

export {
  ProcessSteps,
  formatTargetMixDensity,
  PROCESS_STEP_ORDER,
  MIXING_STEP,
  PRINTING_STEP,
  LOWHEAT_STEP,
  DEPOWDERING_STEP,
  GREEN_BODY_MEAS_STEP,
  HIGH_HEAT_STEP,
  SINTERED_MEAS_STEP,
  CUT_MEAS_STEP,
  QA_MEAS_STEP,
  HIGH_HEAT_RING_TYPES
};